let baseClass = require('@/client/extensions/composition/asyncOperations/requestAdapters/interface.class.js')
let conf      = config.asyncData.requestAdapters.saffronAxios
let _         = require('lodash/object')
import axiosRetry from 'axios-retry'

class SaffronAxiosAdapter extends baseClass.default {
  conf = config.asyncData.requestAdapters.saffronAxios

  axios   = null
  rootUrl = conf.rootUrl
  options = {
    rootUrl    : conf.rootUrl,
    crossDomain: true
  }

  constructor (options) {
    super(options)
    let newAxiosInstance = require('axios').default.create({ timeout: 20000 })
    let retryAttempts    = config.asyncData.requestAdapters.saffronAxios.axiosRetryAttempts
    if (retryAttempts > 0) {
      axiosRetry(newAxiosInstance, { retries: retryAttempts })
    }

    this.axios = newAxiosInstance
    return this
  }

  getRequestOptions (options) {
    return _.merge({}, this.options, options)
  }

  enforceGetAxiosOptionsIntegrity (options) {
    if (typeof options !== 'object') {
      options = {}
    }

    if (options.saffronAxios && typeof options.saffronAxios === 'object') {
      options = {
        ...options,
        ...options.saffronAxios
      }
    }

    if ( ! options.headers) {
      options.headers = {}
    }

    // required for CORS
    if ( ! options.withCredentials) {
      options.withCredentials = true
    }

    if (options.authorization) {
      let token     = options.authorization.token || ''
      let tokenType = options.authorization.tokenType || ''

      if (token && token !== '') {
        options.headers['Authorization'] = `${tokenType} ${token}`
      }

    }

    if (typeof options.asFormData === 'undefined') {
      options.asFormData = false
    }

    return options
  }

  getAxiosPromise (url, data = {}, options = {}, method = 'get') {
    options = this.enforceGetAxiosOptionsIntegrity(options)

    if (options.asFormData) {
      const formData = new FormData()
      for (const [key, value] of Object.entries(data)) {
        formData.append(key, value)
      }
      data = formData
    }
    // allow same api for all methods. get has unique API in axios.
    if (method === 'get') {
      if (typeof options.params == 'undefined') options.params = data
      return this.axios.get(url, options)
    }

    // allow same api for all methods. delete has unique API in axios.
    if (method === 'delete') {
      options.params = data || {};
      return this.axios.delete(url, options)
    }

    return this.axios[method](url, data, options)
  }

  async _fetch (url, data, options, method = 'post') {


    let requestOptions = this.getRequestOptions(options)

    // auto prefix to url, unless requested specifically
    if ( ! options.requestUrlFull) {
      url = requestOptions.rootUrl + url
    }

    // if we try to append stuff to data while it is the original object
    // we may trigger reactive stuff, as data might be reactive
    // so we are making a copy
    if (data && typeof data === 'object') {
      data = {...data};
    }

    // overload locale
    if (data && typeof data === 'object' && ! data.hasOwnProperty('locale') && options.hasOwnProperty('locale')) {
      data.locale = options.locale
    }

    try {
      return await this.getAxiosPromise(url, data, requestOptions, method).then((result) => {
        if ((options.requestReturnType || 'false') === 'all') {
          return result
        } else {
          return result.data
        }
      }).catch((err) => this.parseError(err))
    } catch (err) {
      return this.parseError(err)
    }
  }

  async get (url, data = null, options = {}) {
    return this._fetch(url, data, options, 'get')
  }

  async post (url, data, options = {}) {
    return this._fetch(url, data, options, 'post')
  }

  async put (url, data, options) {
    return this._fetch(url, data, options, 'put')
  }

  async patch (url, data, options) {
    return this._fetch(url, data, options, 'patch')
  }

  async delete (url, data, options) {

    return this._fetch(url, data, options, 'delete')
  }

  parseError (err) {
    let res = err.response

    let final = {
      'status': {
        'code'   : res?.status ?? 408,
        'message': res?.statusText ?? 'connection error'
      },
      data    : res?.data ?? null
    }

    return final
  }
}

export default (options) => {
  return new SaffronAxiosAdapter(options)
};
